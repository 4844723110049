.acat-bg {
  width: 100%;
  height: 100dvh;
  padding: 0.001rem 0;
  background-color: #f8f8f8;
  display: flex;
}

.acat-main {
  max-width: 1200px;
  margin-inline: auto;
  flex-grow: 1;
  border-left: 0.001em solid rgba(80, 80, 80, 0.1);
  border-right: 0.001em solid rgba(80, 80, 80, 0.1);
  background-color: white;
}

.acat-top {
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
}

.acat-title {
  color: var(--primary);
  margin-right: 1.5em;
}

.acat-btns {
  margin-inline: 0.3rem;
  display: grid;
  gap: 0.4rem;
  grid-template-columns: repeat(7, 1fr);
  padding: 2rem;
}

@media (width < 900px) {
  .acat-btns {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (width < 600px) {
  .acat-main {
    padding-block: 4rem;
  }
  .acat-btns {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (width < 460px) {
  .acat-btns {
    grid-template-columns: repeat(3, 1fr);
  }
}
