.loading-background {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container {
  --length: 300px;

  width: var(--length);
  height: var(--length);
  position: relative;
}

.loading-container svg {
  width: 100%;
  height: 100%;
}

.bbq-rod {
  width: 200px;
  aspect-ratio: 16 / 9;
  position: absolute;
  inset: 0;
  top: 1%;
  margin: auto;
  z-index: 10;
}

.veggie {
  --speed: 250ms;

  width: 40px;
  aspect-ratio: 1 / 1;
  position: absolute;
  inset: 0;
  margin: auto;
  padding: 0;
  z-index: 20;
  opacity: 0;
  animation: fade 2500ms linear forwards infinite;
}

.veggie.i {
  --init: -35%;
  animation-delay: calc(1 * var(--speed));
}

.veggie.ii {
  --init: -12%;
  width: 55px;
  animation-delay: calc(2 * var(--speed));
}

.veggie.iii {
  --init: 10%;
  width: 45px;
  animation-delay: calc(3 * var(--speed));
}

.veggie.iv {
  --init: 25%;
  animation-delay: calc(4 * var(--speed));
}

.veggie.v {
  --init: 49%;
  animation-delay: calc(5 * var(--speed));
}

@keyframes fade {
  0% {
    left: 100%;
    opacity: 1;
  }
  10%,
  50% {
    left: var(--init);
  }
  51%,
  100% {
    left: var(--init);
  }
}
