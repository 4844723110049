.expand-icon {
  position: absolute;
  right: 1rem;
  transition: transform 200ms ease-in-out;
  transform-origin: center;
}

.expand-icon.open {
  transform: rotate(180deg);
}
