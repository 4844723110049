@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

*:not(code),
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

*:focus {
  outline: 0;
  outline: none;
}

:root {
  --primary: #23807e;
  --primary-blur: #23807e80;
  --primary-dark: #185958;
  --secondary: #4afcfe;
  --button: #23807e;
  --button-dark: #185958;
  --bg-light: #f2feff;
  --success: #008000;
  --success-light: #cff6cf;
  --tag: #e32636;
  --explore-recipes-bg: #ffeded;
  --explore-recipes-text: #ff460b;
  --recipe-home-bg: #ffd614;
  --recipe-home-text-bg: #ffb100;
}
/*#497e63*/

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

body > iframe {
  pointer-events: none !important;
}
