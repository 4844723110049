.logo-pop {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
}

.form {
  /* min-width: 25vw;
  min-height: 30vh; */
  position: relative;
  margin: auto 0;
  padding: 20px;
  /* display: grid; */
  background: white;
  grid-gap: 10px;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  z-index: 1000;
  position: fixed;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 560px) {
  .form {
    width: 90vw;
  }
}

.form .remove {
  position: absolute;
  top: 10px;
  right: 10px;
}

.create-form {
  max-height: 250px;
  overflow-y: auto;
}

.create-form > div {
  margin: 2% 1%;
  width: 98%;
}

.groupedText {
  display: flex;
  margin: 2% 1%;
}

.groupedText > div {
  flex-grow: 1;
  margin: 0 1%;
}

.groupedCol {
  display: 'flex';
  margin: 2% 1%;
}

.groupedCol > div {
  flex-grow: 1;
  margin: 0 1%;
}

.pop-title {
  text-align: center;
  font-size: larger;
  color: var(--primary);
}

.fields {
  width: 100%;
  /* margin: 10%; */
}

.error {
  font-size: smaller;
  color: red;
}

.check-terms input {
  margin-right: 10px;
}
