.cb-category {
  cursor: pointer;
  transition: scale 200ms ease-in-out;
  width: 100%;
  max-width: 120px;
  margin: auto;
}

.cb-category:hover {
  scale: 1.1;
}

.cb-category-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  z-index: 5;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: inset 0 -50px 50px -50px var(--primary);
}

.cb-category p {
  width: 100%;
  inset: 0;
  top: auto;
  text-align: center;
  color: #23807e;
  z-index: 10;
  font-size: 0.9rem;
  font-weight: 500;
  padding-block: 0.5rem;
  text-align: center;
  text-transform: capitalize;
}

.cb-category-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
}
