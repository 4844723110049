@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);

.cb-category {
  cursor: pointer;
  transition: scale 200ms ease-in-out;
  width: 100%;
  max-width: 120px;
  margin: auto;
}

.cb-category:hover {
  scale: 1.1;
}

.cb-category-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  z-index: 5;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: inset 0 -50px 50px -50px var(--primary);
}

.cb-category p {
  width: 100%;
  inset: 0;
  top: auto;
  text-align: center;
  color: #23807e;
  z-index: 10;
  font-size: 0.9rem;
  font-weight: 500;
  padding-block: 0.5rem;
  text-align: center;
  text-transform: capitalize;
}

.cb-category-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
}

.expand-icon {
  position: absolute;
  right: 1rem;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.expand-icon.open {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.acat-bg {
  width: 100%;
  height: 100dvh;
  padding: 0.001rem 0;
  background-color: #f8f8f8;
  display: flex;
}

.acat-main {
  max-width: 1200px;
  margin-inline: auto;
  flex-grow: 1;
  border-left: 0.001em solid rgba(80, 80, 80, 0.1);
  border-right: 0.001em solid rgba(80, 80, 80, 0.1);
  background-color: white;
}

.acat-top {
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
}

.acat-title {
  color: var(--primary);
  margin-right: 1.5em;
}

.acat-btns {
  margin-inline: 0.3rem;
  display: grid;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  grid-template-columns: repeat(7, 1fr);
  padding: 2rem;
}

@media (max-width: 899px) {
  .acat-btns {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 599px) {
  .acat-main {
    padding-block: 4rem;
  }
  .acat-btns {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 459px) {
  .acat-btns {
    grid-template-columns: repeat(3, 1fr);
  }
}

.loading-background {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container {
  --length: 300px;

  width: var(--length);
  height: var(--length);
  position: relative;
}

.loading-container svg {
  width: 100%;
  height: 100%;
}

.bbq-rod {
  width: 200px;
  aspect-ratio: 16 / 9;
  position: absolute;
  inset: 0;
  top: 1%;
  margin: auto;
  z-index: 10;
}

.veggie {
  --speed: 250ms;

  width: 40px;
  aspect-ratio: 1 / 1;
  position: absolute;
  inset: 0;
  margin: auto;
  padding: 0;
  z-index: 20;
  opacity: 0;
  -webkit-animation: fade 2500ms linear forwards infinite;
          animation: fade 2500ms linear forwards infinite;
}

.veggie.i {
  --init: -35%;
  -webkit-animation-delay: calc(1 * var(--speed));
          animation-delay: calc(1 * var(--speed));
}

.veggie.ii {
  --init: -12%;
  width: 55px;
  -webkit-animation-delay: calc(2 * var(--speed));
          animation-delay: calc(2 * var(--speed));
}

.veggie.iii {
  --init: 10%;
  width: 45px;
  -webkit-animation-delay: calc(3 * var(--speed));
          animation-delay: calc(3 * var(--speed));
}

.veggie.iv {
  --init: 25%;
  -webkit-animation-delay: calc(4 * var(--speed));
          animation-delay: calc(4 * var(--speed));
}

.veggie.v {
  --init: 49%;
  -webkit-animation-delay: calc(5 * var(--speed));
          animation-delay: calc(5 * var(--speed));
}

@-webkit-keyframes fade {
  0% {
    left: 100%;
    opacity: 1;
  }
  10%,
  50% {
    left: var(--init);
  }
  51%,
  100% {
    left: var(--init);
  }
}

@keyframes fade {
  0% {
    left: 100%;
    opacity: 1;
  }
  10%,
  50% {
    left: var(--init);
  }
  51%,
  100% {
    left: var(--init);
  }
}

.logo-pop {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
}

.form {
  /* min-width: 25vw;
  min-height: 30vh; */
  position: relative;
  margin: auto 0;
  padding: 20px;
  /* display: grid; */
  background: white;
  grid-gap: 10px;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  z-index: 1000;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media screen and (max-width: 560px) {
  .form {
    width: 90vw;
  }
}

.form .remove {
  position: absolute;
  top: 10px;
  right: 10px;
}

.create-form {
  max-height: 250px;
  overflow-y: auto;
}

.create-form > div {
  margin: 2% 1%;
  width: 98%;
}

.groupedText {
  display: flex;
  margin: 2% 1%;
}

.groupedText > div {
  flex-grow: 1;
  margin: 0 1%;
}

.groupedCol {
  display: 'flex';
  margin: 2% 1%;
}

.groupedCol > div {
  flex-grow: 1;
  margin: 0 1%;
}

.pop-title {
  text-align: center;
  font-size: larger;
  color: var(--primary);
}

.fields {
  width: 100%;
  /* margin: 10%; */
}

.error {
  font-size: smaller;
  color: red;
}

.check-terms input {
  margin-right: 10px;
}

*:not(code),
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

*:focus {
  outline: 0;
  outline: none;
}

:root {
  --primary: #23807e;
  --primary-blur: #23807e80;
  --primary-dark: #185958;
  --secondary: #4afcfe;
  --button: #23807e;
  --button-dark: #185958;
  --bg-light: #f2feff;
  --success: #008000;
  --success-light: #cff6cf;
  --tag: #e32636;
  --explore-recipes-bg: #ffeded;
  --explore-recipes-text: #ff460b;
  --recipe-home-bg: #ffd614;
  --recipe-home-text-bg: #ffb100;
}
/*#497e63*/

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #23807e;
  background: var(--primary);
  border-radius: 10px;
}

body > iframe {
  pointer-events: none !important;
}

